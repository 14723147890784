<template>
  <v-container class="pa-3" v-if="teamModel">
    <v-dialog v-model="identityDialog">
      <identityDetails :id="thisIdentity"></identityDetails>
    </v-dialog>
    <v-tabs v-model="tab" color="mainTitleLabel" class="mb-3">
      <v-tab>Team Details</v-tab>
      <v-tab>Team Member List</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="text-right">
          <v-btn color="success " class="mr-2" @click="actionSave" :disabled="!valid"> Save</v-btn>
        </div>
        <div class="d-flex justify-space-between align-center">
          <div class="mainTitle">{{ teamModel.label }}</div>
        </div>
        <v-checkbox v-model="teamModel.needApproval" label="Need Approval for all Agent Login" />
        <v-form v-model="valid" lazy-validation>
          <v-row style="margin: -10px -11px">
            <v-col cols="6">
              <v-card elevation="2" min-height="288">
                <v-card-text>
                  <label-with-text-field
                    item-field-name="Activity Labels"
                    v-model="teamModel.activityLabels"
                    :tag="ChipsCombobox"
                  />
                  <label-with-text-field
                    item-field-name="Client Tags"
                    v-model="teamModel.clientTags"
                    :tag="ChipsCombobox"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card elevation="2" min-height="288">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div class="align-center">
                        <span class="itemFieldName mb-3" style="white-space: nowrap"
                          >Working Period</span
                        >
                        <v-autocomplete
                          label="Timezone"
                          class="mt-3 mb-3"
                          v-model="timezone"
                          :items="timezones"
                          hide-details
                          outlined
                        />
                        <v-spacer />
                      </div>
                      <div class="itemFieldName">Max. Chat Idle Time</div>
                      <div style="display: inline-flex">
                        <v-text-field
                          v-model="teamModel.idleTime"
                          style="max-width: 150px"
                          type="number"
                          min="1"
                          step="1"
                          :rules="checkIdleTime"
                          clearable
                        >
                        </v-text-field>
                        <div class="itemFieldName" style="align-self: center; margin-bottom: 6px">
                          Minute(s)
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item style="position: relative">
        <div class="d-flex justify-space-between align-center">
          <div class="mainTitle">
            {{ teamModel.label }}
          </div>
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="mainTitle" style="font-weight: 600">
                Create Profile</v-card-title
              >
              <v-card-text>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" md="6">
                      <label-with-text-field
                        item-field-name="Username"
                        :dense="true"
                        v-model="newUser.username"
                        :required="true"
                        :rules="usernameRules"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <label-with-text-field
                        item-field-name="Password"
                        :dense="true"
                        v-model="newUser.pw"
                        :rules="pwRules"
                        :required="true"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <label-with-text-field
                        item-field-name="Name"
                        :dense="true"
                        v-model="newUser.name"
                        :rules="nameRules"
                        :required="true"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <label-with-text-field
                        item-field-name="Email"
                        :dense="true"
                        v-model="newUser.email"
                        :rules="emailRules"
                        :required="true"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="dialog = false">Close</v-btn>
                <v-btn color="primary" @click="createNewUser()"> Create</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-row style="margin: -11px">
          <v-col cols="4" :key="x._id" v-for="x in teamData">
            <Card :content="x" class="clickable" @click.native="display(x)" />
          </v-col>
        </v-row>
        <div class="mt-2 text-center">
          <v-pagination v-model="page" :length="profile.pages || 1"></v-pagination>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudList, crudCreate } from '@/api/crud';
import listViewPageMixin from '@/services/listViewPageMixin';
import Card from '@/components/Identity/IdentityCard';
import { patchMyTeam, getMyTeam } from '@/api/team';
import { mapActions } from 'vuex';
import LabelWithTextField from '../../components/customFormLabel/labelWithTextField';
import CustomLabel from '../../components/customFormLabel/customLabel';
import identityDetails from '@/views/Identities/identityDetails';
import ChipsCombobox from '@/components/customFormLabel/ChipsCombobox';
import timezones from '@/services/timezones.json';

export default {
  name: 'TeamDetails',
  mixins: [listViewPageMixin],
  components: { CustomLabel, LabelWithTextField, Card, identityDetails },
  props: {
    id: {},
  },

  data() {
    return {
      select: [],
      items: [],
      model: {},
      tab: null,
      teamData: [],
      teamModel: null,
      dialog: false,
      identityDialog: false,
      newUser: {},
      valid: true,
      profile: {},
      nameRules: [v => !!v || 'name is required'],
      pwRules: [v => !!v || 'password is required'],
      checkIdleTime: [v => v > 0 || !v || 'Idle Time must be larger than 0'],
      usernameRules: [v => !!v || 'username is required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      thisIdentity: null,
    };
  },

  methods: {
    ...mapActions('alert', ['updateAlertMessage']),

    async reload() {
      try {
        let teamProfile = await getMyTeam(api, this.id);
        if (teamProfile == null) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'you do not have a team yet, please contact admin',
          });
          this.$router.go(-1);
        }
        this.teamModel = { ...teamProfile };
        this.teamModel.idleTime = this.teamModel.idleTime
          ? Math.round(this.teamModel.idleTime / 60000)
          : null;
        this.profile = await crudList(api, 'im/admin/profiles', {
          limit: 9,
          page: this.page,
          filter: { role: ['manager', 'agent'] },
          populate: 'team',
        });
        this.teamData = this.profile.docs;
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async actionSave() {
      if (this.teamModel.idleTime < 0) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'idle time cannot be negative integers',
        });
      } else {
        try {
          const temp = { ...this.teamModel };
          temp.idleTime = temp.idleTime ? parseInt(temp.idleTime) * 60000 : null;
          await patchMyTeam(api, temp);
          await this.reload();
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Save Success',
            type: 'success',
            color: 'success',
          });
        } catch (err) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: err,
          });
        }
      }
    },
    async display(x) {
      this.thisIdentity = x._id;
      this.identityDialog = true;
    },
    async createNewUser() {
      if (this.$refs.form.validate()) {
        try {
          this.newUser.role = 'agent';
          await crudCreate(api, 'im/admin/profiles', this.newUser);
          this.dialog = false;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Added',
            type: 'success',
            color: 'success',
          });
          await this.reload();
        } catch (err) {}
      } else {
      }
    },
  },
  computed: {
    ChipsCombobox: () => ChipsCombobox,
    timezones() {
      return timezones.map(x => ({
        text: x.text, // `(${x.offset >= 0 ? '+' + x.offset : x.offset}) ${x.value}`,
        value: x.offset + (x.isdst ? -1 : 0),
      }));
    },
    timezone: {
      set(v) {
        this.teamModel.workTimePeriod.timezone = v * 60;
      },
      get() {
        return this.teamModel.workTimePeriod.timezone / 60;
      },
    },
  },
};
</script>
