<script>
import api from '@/api';
import ApiError from '@/api/ApiError';
import { chatList, IChat, readChat } from '@/api/chat';
import ChatController from '@/components/Chat/parts/ChatController';
import { difference, throttle } from 'lodash';

export default {
  name: 'ChatsManager',
  inject: ['chatSocket'],
  render(h) {
    return this.$scopedSlots.default ? this.$scopedSlots.default(this) : this.$slots.default;
  },
  provide() {
    return { chatManager: this };
  },
  data() {
    return {
      /**
       * controllers cache
       * @type {Record<string, ChatController>}
       */
      controllers: {}, // need reactive
      readChatBatch: new Map(), // no need reactive
      readChatBatch_flush: throttle(
        async () => {
          const req = this.readChatBatch;
          this.readChatBatch = new Map();
          try {
            const map = await readChat(api, [...req.keys()], {
              populate: [{ path: 'participants' }, { path: 'senderIdentity' }],
            });
            for (const [k, v] of req.entries()) {
              if (map[k]) {
                v.resolve(map[k]);
              } else {
                const e = ApiError.wrap({
                  message: 'Not Found',
                  code: 'not_found',
                  id: k,
                });
                v.reject(e);
              }
            }
          } catch (e) {
            for (const v of req.values()) {
              v.reject(e);
            }
          }
        },
        50,
        { leading: false },
      ),
    };
  },
  mounted() {
    this.chatSocket.on('updateChat', this.onUpdateChat); // admin / manager
    this.chatSocket.on('assignedChats', this.onAssignedChats); // agent
  },
  destroyed() {
    for (const x of Object.values(this.controllers)) x.dispose();
    this.controllers = {};
  },
  methods: {
    async readChat(id) {
      if (this.readChatBatch.has(id)) {
        return this.readChatBatch.get(id).promise;
      } else {
        const p = new Promise((resolve, reject) => {
          this.readChatBatch.set(id, { resolve, reject, promise: null });
        });
        this.readChatBatch.get(id).promise = p;
        this.readChatBatch_flush();
        return p;
      }
    },
    /**
     * init a new controller if not in cache
     * @param {string} id
     * @param {IChat} [chat] optional. if not provided, will fetch one for you
     */
    async resolveController(id, chat) {
      // ad-hoc get chat
      if (this.controllers[id]) return this.controllers[id];
      if (!chat) chat = await this.readChat(id);
      // need to check again after await
      if (this.controllers[id]) return this.controllers[id];
      this.$set(this.controllers, id, new ChatController(chat, this.chatSocket));
      return this.controllers[id];
    },
    /**
     * helper crud
     * @param {{filter?: any, search?: string, sort?: any, limit?: number, offset?: number, select?: any}} [query]
     */
    async listChats(query) {
      return await chatList(api, { ...query });
    },
    /**
     * todo: reload and keep pagination?
     * if id is not null, patch existing item and resort
     * else, reload all
     * @param {string} id
     * @param [doc]
     */
    onUpdateChat(id, doc) {
      if (doc == null) {
        this.$emit('removeChat', id);
      } else {
        const ctrl = this.controllers[id];
        if (ctrl) {
          Object.assign(ctrl.chat, { ...doc, updated_at: doc?.updated_at || new Date() });
        } else {
          this.$emit('reloadChats');
        }
        this.$emit('updateChat', id, doc);
      }
    },
    /**
     * only emitted for agents
     * @param {string[]} chatIds
     * @param {string[]} old
     */
    onAssignedChats(chatIds, old) {
      const added = difference(chatIds, old);
      const removed = difference(old, chatIds);
      this.$emit('reloadChats');
      this.$emit('assignedChats', chatIds, { added, removed });
      for (const i of removed) {
        this.$emit('removeChat', i);
      }
    },
  },
};
</script>
