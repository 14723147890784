<template>
  <div class="typer" style="position: relative">
    <input
      style="
        background-color: #ffffff;
        padding: 9px 12px 11px;
        margin: 5px 10px;
        border-radius: 21px;
      "
      ref="input"
      type="text"
      placeholder="Type here"
      v-on:keyup.enter="sendMessage"
      v-model="content"
      :disabled="loading"
    />

    <v-icon @click="sendMessage()" large>mdi-send</v-icon>
    <div class="blocked" v-if="blocked">
      <h3>Message is being sent, please wait and don't leave the page</h3>
    </div>
  </div>
</template>

<script>
import ChatController from '@/components/Chat/parts/ChatController';
import { mapActions } from 'vuex';

export default {
  name: 'Typer',
  props: {
    controller: ChatController,
  },
  inject: ['chatManager'],
  data() {
    return {
      content: '',
      loading: false,
      error: false,
      messageId: null,
      blocked: false,
    };
  },

  watch: {
    controller: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.$refs.input.focus());
      },
    },
    'controller.messages': {
      immediate: true,
      handler(x) {
        if (x[x.length - 1] != this.messageId) {
          this.blocked = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async sendMessage() {
      if (this.controller && this.content?.trim()) {
        try {
          this.loading = true;
          this.blocked = true;
          this.messageId = this.controller.messages[this.controller.messages.length - 1]?._id;
          await this.controller.sendMessage({ body: this.content });
          this.content = null;
        } catch (err) {
          if (err.subcode === 'no_chat_gateway') {
            err = new Error(
              `This number has been disconnected from the system. Please contact admin for more information.`,
            );
          }
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: err,
          });
          this.blocked = false;
        } finally {
          this.loading = false;
          this.$nextTick(() => this.$refs.input.focus());
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-text-field {
  padding-top: 0px;
  font-size: 36px;
  margin-top: 0px;
}
::v-deep .v-input {
  flex: 0 1 auto;
}
::v-deep .v-icon.v-icon.v-icon--link {
  font-size: 36px;
}
.blocked {
  background-color: var(--v-primary-base);
  color: var(--v-primary_inverse-base);
  position: absolute;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.typer {
  justify-content: space-evenly;
  padding: 5px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ededed;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);

  input[type='text'] {
    left: 2.5rem;
    padding: 1rem;
    width: 85%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.25rem;
  }
}
</style>
