<template>
  <Chat :selectedChatId="selectedChatId" />
</template>

<script>
import Chat from '@/components/Chat/Chat';

export default {
  name: 'ChatPage',
  components: {
    Chat,
  },
  props: {
    selectedChatId: String,
  },
};
</script>

<style scoped></style>
