<script>
import api from '@/api';
import { createSocket } from '@/api/socket';
import { Socket } from 'socket.io-client';

export default {
  name: 'SocketManager',
  render(h) {
    return this.$scopedSlots.default ? this.$scopedSlots.default(this) : this.$slots.default;
  },
  provide() {
    const self = this;
    return {
      get chatSocket() {
        return self.socket;
      },
    };
  },
  data() {
    return {
      /** @type {Socket} */
      socket: createSocket(api, '/chat', { autoConnect: false }),
    };
  },
  mounted() {
    this.socket.open();
  },
  destroyed() {
    this.socket?.disconnect();
    this.socket = null;
  },
};
</script>
