import { IClientProfile, IProfile } from '@/api/auth/membership';
import { IChat } from '@/api/chat';
import { GlobalSchemaFields, populatedArrayCtor, populatedCtor } from '@/api/mongooseTypes';
import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @param {string} chatId
 * @param {{offset?: number, limit?: number, page?: number,sort?: string}} [params]
 * @return {Promise<{docs: IActivity[], total: number, page: number, pages: number, offset: number, limit: number}>}
 */
export async function readActivities(api, chatId, params) {
  const { data } = await api.get(`im/chats/${chatId}/activities`, { params });
  return {
    ...data,
    docs: populatedArrayCtor(data.docs, IActivity),
  };
}

/**
 * @param {AxiosInstance} api
 * @param {string} chatId
 * @param {Partial<IActivity>} payload
 */
export async function createActivity(api, chatId, payload) {
  return populatedCtor((await api.post(`im/chats/${chatId}/activities`, payload)).data, IActivity);
}

/**
 * @param {AxiosInstance} api
 * @param {string} chatId
 * @param {string} activityId
 * @param {Partial<IActivity>} patch
 */
export async function patchActivity(api, chatId, activityId, patch) {
  return populatedCtor(
    (await api.patch(`im/chats/${chatId}/activities/${activityId}`, patch)).data,
    IActivity,
  );
}

/**
 * @param {AxiosInstance} api
 * @param {string} chatId
 * @param {string} activityId
 */
export async function deleteActivity(api, chatId, activityId) {
  await api.delete(`im/chats/${chatId}/activities/${activityId}`);
}

export class IActivity extends GlobalSchemaFields {
  /** @type {IChat} */
  chat;
  /**
   * readonly
   * @type {IClientProfile}
   */
  _sender;
  /** @type {IProfile} */
  created_by;
  /** @type {IProfile} */
  updated_by;
  /** @type {string} */
  status;
  /** @type {boolean} */
  isSystem;
  /**@type {string} */
  content;

  constructor(obj) {
    super(obj);
    obj.chat = populatedCtor(obj.chat, IChat);
    obj._sender = populatedCtor(obj._sender, IClientProfile);
    obj.created_by = populatedCtor(obj.created_by, IProfile);
    obj.updated_by = populatedCtor(obj.updated_by, IProfile);
    Object.assign(this, obj);
  }
}
