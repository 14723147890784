var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticStyle:{"background-color":"#f6f6f6","padding":"4px 8px"}},[_c('v-row',{staticStyle:{"min-height":"64px"}},[_c('v-col',{staticClass:"d-flex justify-space-around"},[_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([(_vm.isAgent)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"5px"},attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.filter.title)+" "),_c('v-icon',[_vm._v(" mdi-chevron-down")])],1)]}}:null],null,true)},[_c('v-list',_vm._l((_vm.filterItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.filter = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.sort.title)+" "),_c('v-icon',[_vm._v(" mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.sortItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.sort = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1),_c('v-list',{staticClass:"scrollable flex-grow-1"},[_vm._l((_vm.chats),function(x){return [_c('ChatControllerConsumer',{key:x._id,attrs:{"chatId":x._id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var chat = ref.chat;
var controller = ref.controller;
return [_c('ChatListItem',{attrs:{"inputValue":_vm.selectedChatId === x._id,"chat":chat,"lastMessage":controller && controller.lastMessage},on:{"click":function($event){return _vm.$emit('update:selectedChatId', x._id)}}})]}}],null,true)})]}),(_vm.loading)?_c('v-list-item',{key:"1"},[_c('v-list-item-content',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):(_vm.hasMore)?_c('v-list-item',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],key:"2"},[_c('v-list-item-content',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_c('v-list-item',{key:"3"},[_c('v-list-item-content',[_c('div',{staticClass:"text--secondary",staticStyle:{"text-align":"center","font-size":"smaller"}},[_vm._v(" - no more - ")])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }