<template>
  <div class="d-flex flex-column">
    <div style="background-color: #f6f6f6; padding: 4px 8px">
      <v-row style="min-height: 64px">
        <!--        <v-col style="position: relative">-->
        <!--          <input-->
        <!--            style="-->
        <!--              background-color: #ffffff;-->
        <!--              padding: 8px 0px 8px 11px;-->
        <!--              outline: none;-->
        <!--              width: 100%;-->
        <!--              border-radius: 21px;-->
        <!--            "-->
        <!--            ref="input"-->
        <!--            type="text"-->
        <!--            placeholder="Search"-->
        <!--            v-model="search"-->
        <!--          />-->
        <!--        </v-col>-->
        <v-col class="d-flex justify-space-around">
          <v-menu offset-y transition="scale-transition">
            <template v-slot:activator="{ on, attrs }" v-if="isAgent">
              <v-btn color="primary" v-bind="attrs" v-on="on" style="margin-right: 5px">
                {{ filter.title }}
                <v-icon> mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in filterItems" :key="index" @click="filter = item">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">
                {{ sort.title }}
                <v-icon> mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in sortItems" :key="index" @click="sort = item">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>

    <v-list class="scrollable flex-grow-1">
      <template v-for="x in chats">
        <ChatControllerConsumer :chatId="x._id" v-slot="{ chat, controller }" :key="x._id">
          <ChatListItem
            :inputValue="selectedChatId === x._id"
            @click="$emit('update:selectedChatId', x._id)"
            :chat="chat"
            :lastMessage="controller && controller.lastMessage"
          />
        </ChatControllerConsumer>
      </template>
      <v-list-item key="1" v-if="loading">
        <v-list-item-content>
          <v-progress-circular indeterminate color="primary" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="2" v-else-if="hasMore" v-intersect="{ handler: onIntersect }">
        <v-list-item-content>
          <v-progress-circular indeterminate color="primary" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="3" v-else>
        <v-list-item-content>
          <div class="text--secondary" style="text-align: center; font-size: smaller">
            - no more -
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
.scrollable {
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: auto;
}
</style>

<script>
import ChatControllerConsumer from '@/components/Chat/parts/ChatControllerConsumer';
import ChatListItem from '@/components/Chat/parts/ChatListItem';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ChatList',
  components: { ChatControllerConsumer, ChatListItem },
  props: {
    selectedChatId: String,
  },
  inject: ['chatManager'],
  data() {
    return {
      search: null,
      filter: { title: 'All', value: null },
      filterItems: [
        { title: 'All', value: null },
        {
          title: 'Not Assigned',
          value: { '_assignedAgents.0': { $exists: false } },
        },
        { title: 'Assigned', value: { '_assignedAgents.0': { $exists: true } } },
        // { title: 'Archived', value: { enabled: false } },
      ],
      sort: { title: 'New', value: { updated_at: -1 } },
      sortItems: [
        { title: 'New', value: { updated_at: -1 } },
        { title: 'Old', value: { updated_at: 1 } },
      ],
      /** @type {IChat[]} */
      chats: [],
      hasMore: true,
      loading: false,
      debouncedReload: debounce(this.reload, 300),
    };
  },
  watch: {
    computedQuerySerialized: {
      immediate: false,
      handler() {
        this.debouncedReload();
      },
    },
  },
  mounted() {
    this.chatManager.$on('updateChat', this.refresh);
    this.chatManager.$on('removeChat', this.removeChat);
    this.chatManager.$on('reloadChats', this.refresh);
  },
  destroyed() {
    this.chatManager.$off('updateChat', this.refresh);
    this.chatManager.$off('removeChat', this.removeChat);
    this.chatManager.$off('reloadChats', this.refresh);
  },
  computed: {
    ...mapGetters('auth', ['role']),
    computedQuery() {
      return {
        sort: this.sort?.value,
        filter: this.filter?.value,
        search: this.search || undefined,
      };
    },
    computedQuerySerialized() {
      return JSON.stringify(this.computedQuery);
    },
    isAgent() {
      return this.role !== 'agent';
    },
  },
  methods: {
    async reload() {
      if (this.loading) return;
      try {
        this.loading = true;
        const { docs, total, offset } = await this.chatManager.listChats({
          ...this.computedQuery,
          select: '_id',
        });
        this.chats = docs;
        this.hasMore = total > offset + docs.length;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async loadMore() {
      if (this.loading) return;
      try {
        this.loading = true;
        const { docs, total, offset } = await this.chatManager.listChats({
          ...this.computedQuery,
          select: '_id',
          offset: this.chats.length,
        });
        this.chats = this.chats.concat(docs);
        this.hasMore = total > offset + docs.length;
      } catch (e) {
        this.hasMore = false;
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      if (this.loading) return;
      try {
        this.loading = true;
        const { docs, total, offset } = await this.chatManager.listChats({
          ...this.computedQuery,
          select: '_id',
          offset: 0,
          limit: this.chats.length + 1,
        });
        this.chats = docs;
        this.hasMore = total > offset + docs.length;
      } finally {
        this.loading = false;
      }
    },
    onIntersect(e) {
      if (!this.loading && this.hasMore && e[0]?.isIntersecting) {
        this.loadMore();
      }
    },
    removeChat(id) {
      this.chats = this.chats.filter(x => x._id !== id);
    },
  },
};
</script>

<style scoped></style>
