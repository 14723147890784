import api from '@/api';
import { IChat, IChatMessage, sendMessage } from '@/api/chat';
import ChatController from '@/components/Chat/parts/ChatController';
import { getId } from '@/services/filters';

export default class DirectSendMessageController extends ChatController {
  /**
   * sorted by created_at Ascending
   * @type {IChatMessage[]}
   */
  messages = [];

  /** @type {IClientProfile} */
  sender = null;
  /** @type {IClientProfile} */
  recipient = null;
  sendSwitchToken = true;

  /**
   * @param {IClientProfile} sender
   * @param {IClientProfile} recipient
   * @param {string} platform
   * @param {IChat} previousChat
   */
  constructor({ sender, recipient, platform }) {
    super(
      new IChat({
        _id: 'DirectSendMessageController',
      }),
      null,
    );
    this.sender = sender;
    this.recipient = recipient;
    this.platform = platform;
  }

  join() {
    return Promise.resolve();
  }

  leave() {}

  dispose() {}

  getLastMessage() {
    return Promise.resolve(null);
  }

  getMessages(query) {
    return Promise.resolve([]);
  }

  loadBefore(limit) {
    return Promise.resolve([]);
  }

  loadAfter(limit) {
    return Promise.resolve([]);
  }

  /**
   * @param {string} body
   * @return {Promise<*>}
   */
  async sendMessage({ body }) {
    // patch @ 20211004 send a generated token to the new chat to identify the existing recipient profile
    // this flow is only for chatapi platform (whatsapp)
    if (this.sendSwitchToken) {
      this.sendSwitchToken = false;
      if (this.platform === 'chatapi') {
        await sendMessage(api, {
          from: getId(this.sender),
          toProfile: getId(this.recipient),
          platform: this.platform,
          // do not change this string format
          // see exact RegEx in server code ChatApiChatGateway.ts
          //body: `-- Switching sender --\r\n#${this.sender.sid}${this.recipient.sid}`,
          body: `- ref: ${this.recipient.sid} -`,
        });
      }
    }
    return sendMessage(api, {
      from: getId(this.sender),
      toProfile: getId(this.recipient),
      platform: this.platform,
      body,
    });
  }
}
