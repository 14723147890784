import { IChatMessage } from '@/api/chat';
import { populatedArrayCtor, populatedCtor } from '@/api/mongooseTypes';
import { getId } from '@/services/filters';
import { Socket } from 'socket.io-client';

export default class ChatController {
  /**
   * sorted by created_at Ascending
   * @type {IChatMessage[]}
   */
  messages = [];
  _joinCount = 0;

  get lastMessage() {
    return this.messages[this.messages.length - 1];
  }

  /**
   * @param {IChat} chat
   * @param {Socket} socket
   */
  constructor(chat, socket) {
    /** @type {IChat} */
    this.chat = chat;
    /** @type {Socket} */
    this.socket = socket;
    if (socket) socket.on('msg', msg => this.appendMessage(populatedCtor(msg, IChatMessage)));
    this.getLastMessage().then(x => x && this.appendMessage(x));
  }

  get id() {
    return this.chat?._id;
  }

  /**
   * begin listen to chat events
   */
  join() {
    if (this._joinCount++ === 0) {
      return new Promise((yes, no) => {
        this.socket.emit('join', this.id, err => (err ? no(err) : yes()));
      });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * stop listen
   */
  leave() {
    if (--this._joinCount <= 0) {
      this.socket.emit('leave', this.id);
    }
  }

  /**
   * cleanup resources
   */
  dispose() {
    this._joinCount = 0;
    this.leave();
    this.socket.off('msg', this.appendMessage);
  }

  /**
   * @return {Promise<IChatMessage>}
   */
  getLastMessage() {
    return new Promise((yes, no) => {
      this.socket.emit('getLastMessage', this.id, (err, msg) => {
        if (err) no(err);
        else {
          yes(populatedCtor(msg, IChatMessage));
        }
      });
    });
  }

  /**
   * @param {{limit?: number, before?: Date, after?: Date}} query
   * @return {Promise<IChatMessage[]>}
   */
  getMessages(query) {
    return new Promise((yes, no) => {
      this.socket.emit('getMessages', this.id, query || null, (err, docs) => {
        if (err) no(err);
        else {
          yes(populatedArrayCtor(docs, IChatMessage));
        }
      });
    });
  }

  /**
   * @param {number} [limit]
   */
  async loadBefore(limit) {
    const list = await this.getMessages({ before: this.messages[0]?.created_at, limit });
    this.appendMessage(list);
    return list;
  }

  /**
   * @param {number} [limit]
   */
  async loadAfter(limit) {
    const after = this.lastMessage?.created_at;
    const list = await this.getMessages({ after, limit });
    this.appendMessage(list);
    return list;
  }

  /**
   * @param {IChatMessage | IChatMessage[]} msg
   */
  appendMessage = msg => {
    const list = this.messages;
    let modified = false;
    const insert = m => {
      const found = binaryInsert(list, m, msgComparator, getId);
      if (found < 0) {
        modified = true;
      } else if (list[found].updated_at !== m.updated_at) {
        list.splice(found, 1, m);
        modified = true;
      }
    };
    if (Array.isArray(msg)) {
      for (const m of msg) {
        if (getId(m.chat) === this.id) {
          insert(m);
        }
      }
    } else {
      if (getId(msg.chat) === this.id) {
        insert(msg);
      }
    }
  };

  /**
   * @param {string} body
   * @return {Promise<unknown>}
   */
  sendMessage({ body }) {
    return new Promise((yes, no) => {
      this.socket.emit('sendMessage', { chat: this.id, body }, (err, res) => {
        if (err) no(err);
        else yes(res);
      });
    });
  }
}

/**
 * @param {IChatMessage} a
 * @param {IChatMessage} b
 * @return {number}
 */
function msgComparator(a, b) {
  return a.created_at - b.created_at;
}

/**
 * @param {*[]} arr
 * @param {*} target
 * @param {Function} comparator sort comparator
 * @return {number} found index, or bitwise complement of nearest index
 */
function binarySearch(arr, target, comparator = (a, b) => (a < b ? -1 : a > b ? 1 : 0)) {
  let l = 0,
    h = arr.length - 1,
    m,
    comparison;
  while (l <= h) {
    m = (l + h) >>> 1; /* equivalent to Math.floor((l + h) / 2) but faster */
    comparison = comparator(arr[m], target);
    if (comparison < 0) {
      l = m + 1;
    } else if (comparison > 0) {
      h = m - 1;
    } else {
      return m;
    }
  }
  return ~l;
}

/**
 * @param {*[]} arr
 * @param {*} target
 * @param {Function} comparator sort comparator
 * @param {Function} keyComparator object equals comparator
 * @return {number} index if found existing. -index if inserted
 */
function binaryInsert(
  arr,
  target,
  comparator = (a, b) => (a < b ? -1 : a > b ? 1 : 0),
  keyComparator = getId,
) {
  let i = binarySearch(arr, target, comparator);
  if (i >= 0) {
    /* if the binarySearch return value was zero or positive, a matching object was found */
    if (keyComparator(arr[i]) === keyComparator(target)) {
      return i;
    }
  } else {
    /* if the return value was negative, the bitwise complement of the return value is the correct index for this object */
    i = ~i;
  }
  arr.splice(i, 0, target);
  return -i;
}
