<script>
export default {
  name: 'ChatControllerConsumer',
  props: {
    chatId: String,
  },
  inject: ['chatManager'],
  render(h) {
    return this.$scopedSlots.default ? this.$scopedSlots.default(this) : this.$slots.default;
  },
  data() {
    return {
      controller: null,
      error: null,
    };
  },
  watch: {
    chatId: {
      immediate: true,
      async handler(id) {
        try {
          this.controller = id ? await this.chatManager.resolveController(id) : null;
          this.error = null;
        } catch (e) {
          this.error = e;
        }
      },
    },
    controller: {
      immediate: true,
      handler(_new, old) {
        if (_new !== old) {
          if (old) old.leave();
          if (_new) _new.join();
        }
      },
    },
  },
  mounted() {
    this.chatManager.$on('removeChat', this.removeChat);
  },
  destroyed() {
    this.chatManager.$off('removeChat', this.removeChat);
    this.controller?.leave();
  },
  computed: {
    chat() {
      return this.controller?.chat;
    },
    messages() {
      return this.controller?.messages;
    },
  },
  methods: {
    removeChat(id) {
      if (this.chatId === id) {
        this.$emit('removeChat', this.chat);
      }
    },
  },
};
</script>
