<template>
  <v-dialog v-model="valueProxy" width="80%" @create="reload" @update="reload" @delete="reload">
    <v-card>
      <v-card-title class="justify-end">
        {{ title }}
        <v-btn icon @click="valueProxy = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="addNew">
          <label-with-text-field
            :dense="true"
            item-field-name="Add New Activity Track"
            v-model="form.content"
            :rules="rules.content"
          />

          <!--            <v-text-field v-model="form.content" :rules="rules.content" />-->
          <custom-label item-field-name="Status" />
          <v-autocomplete
            ref="input"
            outlined
            dense
            v-model="form.status"
            :items="items"
            :rules="rules.status"
            :search-input.sync="autoSearch"
            item-text="activityLabels"
            return-object
            hide-selected
            hide-no-data
            :item-value="x => x"
          >
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="items[i]"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-autocomplete>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="addNew()">Add New</v-btn>
            <v-btn @click="clear()">Clear</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-card-text>
        <div class="text-right mb-3">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn ico color="primary" v-bind="attrs" v-on="on">
                <v-icon class="mr-1">mdi-tune-vertical</v-icon>
                {{ sort }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="sorting(index)" v-for="(item, index) in sortItems" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-row no-gutters class="mb-3 itemFieldName">
          <v-col cols="6"> Activity Title </v-col>
          <v-col cols="2">Status</v-col>
          <v-col cols="2"> Date </v-col>
        </v-row>
        <v-row
          class="mb-3 pb-2 text"
          no-gutters
          v-for="(n, i) in list.docs"
          :key="n._id"
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); height: 40px"
          ><template v-if="isEdit[i]">
            <v-col cols="6"
              ><v-text-field
                v-model="n.content"
                height="30px"
                style="padding-top: 0; margin-top: 0; max-width: 90%"
            /></v-col>
            <v-col cols="2"
              ><v-autocomplete
                dense
                v-model="n.status"
                :items="items"
                :rules="rules.status"
                :search-input.sync="autoSearch1"
                item-text="activityLabels"
                return-object
                hide-selected
                hide-no-data
                :item-value="x => x"
                style="max-width: 80%"
            /></v-col>
            <v-col cols="2">{{ n.updated_at | moment('YYYY-MM-DD HH:mm') }}</v-col>
            <v-col cols="2"
              ><v-btn @click="save(n, i)" class="mr-3" small color="success">Save</v-btn>
              <v-btn @click="cancelEdit(i)" small>Cancel</v-btn></v-col
            >
          </template>
          <template v-else>
            <v-col cols="6">{{ n.content }}</v-col>
            <v-col cols="2">{{ n.status || 'Empty' }}</v-col>
            <v-col cols="2">{{ n.updated_at | moment('YYYY-MM-DD HH:mm') }}</v-col>
            <v-col cols="2"
              ><template v-if="!isAdmin"
                ><v-btn @click="editActivity(i)" v-if="!n.isSystem" small color="primary"
                  >Edit</v-btn
                ></template
              ></v-col
            >
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiError from '@/api/ApiError';
import { mapActions } from 'vuex';
import editDialogMixin from '@/services/editDialogMixin';
import { createActivity, readActivities, patchActivity } from '@/api/activity';
import api from '@/api';
import { getMyTeam } from '@/api/team';
import { mapGetters } from 'vuex';
import { crudRead } from '@/api/crud';
import LabelWithTextField from '../customFormLabel/labelWithTextField';
import CustomLabel from '../customFormLabel/customLabel';

export default {
  name: 'ActivityDialog',
  components: { CustomLabel, LabelWithTextField },
  mixins: [editDialogMixin],
  emits: ['create'],
  data() {
    return {
      crudURL: '',
      form: { content: '' },
      rules: {
        content: [v => !!v || 'Content is required'],
        status: [v => !!v || 'Status is required'],
      },
      valid: false,
      page: 1,
      btn: [],
      items: [],
      sort: 'All',
      isEdit: [],
      sortItems: [
        { title: 'All', value: 0 },
        { title: 'By Manager/Agent', value: 1 },
        { title: 'By System', value: 2 },
      ],
      autoSearch: '',
      autoSearch1: '',
      list: { docs: [], total: 0 },
    };
  },
  props: {
    title: {
      default: '',
    },
    id: {},
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
  },
  watch: {
    id(v) {
      if (v) this.page = 1;
    },
    async autoSearch1(val) {
      await this.searchFunction(val);
    },
    async autoSearch(val) {
      await this.searchFunction(val);
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async searchFunction(val) {
      try {
        if (this.isAdmin) {
          const team = await crudRead(api, 'im/chats', this.id, {
            populate: { path: 'participant' },
            select: '_team',
          });
          const res = await crudRead(api, 'im/admin/teams', team._team, {
            search: val,
            select: 'activityLabels',
          });
          this.items = res.activityLabels;
        } else {
          const res = await getMyTeam(api, {
            search: val,
            select: 'activityLabels',
          });
          this.items = res.activityLabels;
        }
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async reload() {
      try {
        if (this.id) {
          this.list = await readActivities(api, this.id, {
            sort: { updated_at: -1 },
            populate: ['created_by'],
            page: this.page,
          });
        } else {
          this.list = { docs: [], total: 0 };
        }
        this.isEdit = new Array(this.list.docs.length).fill(false);
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    editActivity(n) {
      this.$set(this.isEdit, n, true);
    },
    cancelEdit(n) {
      this.$set(this.isEdit, n, false);
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
    async addNew() {
      if (!this.form.content || !this.form.content.length) return;
      if (!this.form.status) return;
      try {
        await createActivity(api, this.id, {
          content: this.form.content,
          status: this.form.status,
        });
        await this.reload();
        this.$refs.form.reset();
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Success',
          type: 'success',
          color: 'success',
        });
      } catch (e) {}
    },
    clear() {
      this.$refs.form.reset();
    },
    async save(activity, i) {
      try {
        await patchActivity(api, this.id, activity._id, activity);
        this.$set(this.isEdit, i, false);
        await this.reload();
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async resort(x) {
      try {
        switch (x) {
          case 0: {
            this.list = await readActivities(api, this.id, {
              sort: { updated_by: -1 },
              populate: ['created_by'],
              page: this.page,
            });
            break;
          }
          case 1: {
            this.list = await readActivities(api, this.id, {
              sort: { updated_by: -1 },
              populate: ['created_by'],
              page: this.page,
              filter: { isSystem: { $ne: true } },
            });
            break;
          }
          case 2: {
            this.list = await readActivities(api, this.id, {
              sort: { updated_by: -1 },
              populate: ['created_by'],
              page: this.page,
              filter: { isSystem: true },
            });
            break;
          }
        }
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    sorting(index) {
      switch (index) {
        case 0: {
          this.sort = this.sortItems[0].title;
          break;
        }
        case 1: {
          this.sort = this.sortItems[1].title;
          break;
        }
        case 2: {
          this.sort = this.sortItems[2].title;
          break;
        }
      }
      this.resort(index);
    },
  },
};
</script>

<style scoped lang="scss">
.custom-scroll-area {
  height: 335px;
  overflow-y: auto;
}
.text {
  color: #8898aa;
  font-size: 14px;
}

.itemFieldName {
  font-weight: 600;
  color: #525f7f;
  font-size: 14px;
}
</style>
