<template>
  <div
    style="overflow-y: auto; position: relative"
    @scroll="onScroll"
    @mousewheel="onMouseWheel"
    @touchstart="onMouseWheel"
    ref="scroller"
    class="root scroller"
  >
    <div
      class="v-row d-flex justify-center"
      style="position: absolute; top: 0; left: 0; right: 0; margin: 24px"
    >
      <v-progress-circular indeterminate color="primary" v-if="loading" />
    </div>
    <v-container fluid style="padding: 0" class="chat-container" ref="chatContainer">
      <template v-if="noMoreBefore">
        <div class="text-center" style="margin-bottom: 10px">
          <v-chip color="default" small text>{{ computedDate }}</v-chip>
        </div>
      </template>
      <v-icon
        v-if="scrollDownBtn"
        ref="scrollDownBtn"
        @click="scrollToEnd"
        title="Go to top"
        class="scrollDownBtn"
      >
        mdi-chevron-down Top
      </v-icon>
      <template v-if="messages">
        <template v-for="(msg, i) in messages">
          <div
            class="text-center"
            style="margin-bottom: 10px"
            :key="i"
            v-if="isSameDate(messages[i], messages[i - 1])"
          >
            <v-chip color="default" small text>
              {{ isSameDate(messages[i], messages[i - 1]) }}
            </v-chip>
          </div>
          <Message
            :key="msg._id"
            :msg="msg"
            :showUser="showUser(msg, i)"
            :isOwn="oidEquals(msg.author, senderIdentity)"
          />
        </template>
      </template>
    </v-container>
    <v-snackbar timeout="-1" v-model="newMessage" v-if="newMessage">
      New Message
      <template v-slot:action>
        <v-btn
          text
          @click="
            () => {
              newMessage = false;
              scrollToEnd();
            }
          "
        >
          Go
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ChatController from '@/components/Chat/parts/ChatController';
import Message from '@/components/Chat/parts/Message';
import { getId } from '@/services/filters';
import moment from 'moment';
import { oidEquals } from '@/services/utils';

export default {
  name: 'MessageList',
  props: {
    controller: ChatController,
  },
  components: {
    Message,
  },
  inject: ['chatManager'],
  data() {
    return {
      loading: false,
      currentScrollTop: 0,
      newMessage: false,
      totalChatHeight: 0,
      scrollDownBtn: false,
      scrollBeforeThreshold: 100, // px
      noMoreBefore: false,
      date: null,
    };
  },
  watch: {
    async messagesWatcher(newVal, oldVal) {
      const scroller = await this.$awaitRef('scroller');
      if (
        scroller.scrollHeight - scroller.scrollTop - scroller.clientHeight <
        this.scrollBeforeThreshold
      ) {
        this.scrollToEnd();
      } else {
        // toast when same chat but last message is diff and not isSender
        if (
          newVal?.chat === oldVal?.chat &&
          newVal._id !== oldVal._id &&
          newVal.author.isSender !== true
        ) {
          this.newMessage = true;
        }
      }
    },
    controller(_new, old) {
      if (_new && _new !== old) {
        this.initLoad();
      }
    },
  },
  computed: {
    /**
     * @type {IChatMessage[]}
     */
    messages() {
      return this.controller?.messages || [];
    },
    /**
     * @type {string}
     */
    senderIdentity() {
      return this.controller?.chat?.senderIdentity;
    },
    messagesWatcher() {
      return this.controller?.lastMessage;
    },
    computedDate() {
      const date = this.messages[0]?.created_at;
      const today = moment(date).isSame(new Date(), 'day');
      if (today) {
        return moment(date).format('A h:mm');
      } else return moment(date).format('YYYY-MM-DD');
    },
  },
  methods: {
    oidEquals,
    async initLoad() {
      this.noMoreBefore = false;
      await this.initFillView();
    },
    async initFillView() {
      while (!(await this.isContentFilledView())) {
        if (!(await this.loadBefore())) break;
      }
      await this.$nextTick();
      this.scrollToEnd();
    },
    showUser(m, i) {
      return getId(this.messages[i - 1]?.author) !== getId(m.author);
    },
    onMouseWheel() {
      if (this.newMessage) {
        this.onScroll();
      }
    },
    async onScroll() {
      const scroller = await this.$awaitRef('scroller');
      const scrollValue = scroller.scrollTop;
      const scrollDownValue = scroller.scrollHeight - scroller.scrollTop - scroller.offsetHeight; // scrollDown
      this.scrollDownBtn = scrollDownValue > this.scrollBeforeThreshold;
      if (scrollDownValue <= 0) this.newMessage = false;
      // handle scroll to top load before
      if (scrollValue < this.scrollBeforeThreshold && !this.loading) {
        if (scrollValue < this.currentScrollTop) {
          const oldTop = scroller.scrollTop || 0;
          const oldHeight = scroller.scrollHeight || 0;
          //scrolling upwards
          if (await this.loadBefore()) {
            await this.$nextTick();
            const newHeight = scroller.scrollHeight || 0;
            const diff = newHeight - oldHeight;
            scroller.scrollTop = oldTop + diff;
          }
        }
      }
      this.currentScrollTop = scrollValue;
    },
    async loadBefore(limit) {
      const c = !this.noMoreBefore && this.controller;
      if (c) {
        try {
          this.loading = true;
          const hasMore = (await c.loadBefore(limit)).length > 0;
          this.noMoreBefore = !hasMore;
          return hasMore;
        } finally {
          this.loading = false;
        }
      }
    },
    isSameDate(newMsg, oldMsg) {
      if (newMsg && oldMsg) {
        const temp = this.datePrettify(newMsg.created_at);
        const temp2 = this.datePrettify(oldMsg.created_at);
        if (temp !== temp2) {
          return temp;
        }
      }
    },
    datePrettify(v, longFormat = 'YYYY-MM-DD', now = new Date()) {
      if (v == null) {
        return '';
      }
      v = moment(v);
      const isToday = +v.clone().startOf('day') === +moment().startOf('day');
      return isToday ? 'Today' : v.format(longFormat);
    },
    async isContentFilledView() {
      const [scroller, chatContainer] = await Promise.all([
        this.$awaitRef('scroller'),
        this.$awaitRef('chatContainer'),
      ]);
      return scroller.clientHeight <= chatContainer.clientHeight;
    },
    scrollToEnd() {
      const scroller = this.$refs.scroller;
      scroller.scrollTop = scroller.scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollDownBtn {
  background-color: white;
  position: fixed !important;
  right: 30px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  bottom: 100px;
}

.root {
  background-color: #f6f6f6;
}
.scroller {
  scroll-behavior: smooth;
  padding: 10px 9%;
}
</style>
