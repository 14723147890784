<template>
  <div class="d-flex flex-column">
    <MessageListToolbar
      :chat="chat"
      :switchSender.sync="switchSender"
      @showActivityDialog="showActivityDialog = true"
      @showAssignAgentDialog="showAssignAgentDialog = true"
      @switchToChat="x => $emit('switchToChat', x)"
    />
    <template v-if="oidEquals(switchSender, chat.sender)">
      <MessageList class="flex-grow-1" :controller="controller" />
      <Typer :controller="controller" />
    </template>
    <template v-else>
      <MessageList class="flex-grow-1" :controller="createSwitchSenderController" />
      <Typer :controller="createSwitchSenderController" />
    </template>
    <agent-assign-dialog
      v-if="isAdmin || isManager"
      v-model="showAssignAgentDialog"
      :id="chat._id"
    />
    <activity-dialog v-model="showActivityDialog" :id="chat._id" />
  </div>
</template>

<script>
import api from '@/api';
import { readActivities } from '@/api/activity';
import { IChat } from '@/api/chat';
import ActivityDialog from '@/components/Activity/ActivityDialog';
import AgentAssignDialog from '@/components/Agent/AgentAssignDialog';
import ChatController from '@/components/Chat/parts/ChatController';
import DirectSendMessageController from '@/components/Chat/parts/DirectSendMessageController';
import MessageList from '@/components/Chat/parts/MessageList';
import MessageListToolbar from '@/components/Chat/parts/MessageListToolbar';
import Typer from '@/components/Chat/parts/Typer';
import { oidEquals } from '@/services/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'ChatPanel',
  components: {
    ActivityDialog,
    AgentAssignDialog,
    MessageListToolbar,
    MessageList,
    Typer,
  },
  props: {
    controller: ChatController,
    chat: IChat,
  },
  data() {
    return {
      switchSender: null,
      showActivityDialog: false,
      showAssignAgentDialog: false,
      activityLog: {},
    };
  },
  watch: {
    'chat._id'(v) {
      if (v) {
        this.reloadActivityLog();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isManager']),
    createSwitchSenderController() {
      return new DirectSendMessageController({
        sender: this.switchSender,
        recipient: this.chat.recipient,
        platform: this.chat.platform,
        previousChat: this.chat,
      });
    },
  },
  methods: {
    oidEquals,
    async reloadActivityLog() {
      try {
        this.activityLog = await readActivities(api, this.chat._id, {
          sort: { created_at: -1 },
          filter: { isSystem: null },
          populate: ['created_by'],
          page: this.page,
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
  },
};
</script>
