<template>
  <div class="message-row" :class="{ own: isOwn, showUser }">
    <div class="content">
      <div class="username" v-if="showUser">
        {{ msg.author | profileDisplayName }}
      </div>
      <template v-if="msg.type === 'image'">
        <a :href="resolveAttachment(msg.body)" target="_blank">
          <img :src="resolveAttachment(msg.body)" style="max-height: 300px; max-width: 100%" />
        </a>
        <div v-if="getCaption(msg)">{{ getCaption(msg) }}</div>
      </template>
      <template v-else-if="msg.type === 'audio' || msg.type === 'ptt'">
        <audio :src="resolveAttachment(msg.body)" controls style="height: 2em" />
        <div v-if="getCaption(msg)">{{ getCaption(msg) }}</div>
      </template>
      <template v-else-if="msg.type === 'video'">
        <div v-if="/video upload disabled/i.test(msg.body)">
          📹 <span class="text--disabled" style="font-style: italic">{{ msg.body }}</span>
          <span class="timePlaceholder"></span>
        </div>
        <video v-else :src="resolveAttachment(msg.body)" controls style="max-height: 300px" />
        <div v-if="getCaption(msg)">{{ getCaption(msg) }}</div>
      </template>
      <template v-else-if="msg.type === 'document'">
        📎
        <a :href="resolveAttachment(msg.body)" target="_blank">
          {{ getCaption(msg) }}
        </a>
      </template>
      <template v-else-if="msg.type === 'location'">
        📍
        <a
          :href="
            'https://www.google.com/maps/search/?api=1&query=' +
            encodeURIComponent(msg.body.split(';').join(','))
          "
          target="_blank"
        >
          {{ msg.body }}
        </a>
      </template>
      <template v-else>
        <div style="position: relative">
          <span style="white-space: pre-line; overflow-wrap: anywhere">{{ msg.body }}</span>
          <span class="timePlaceholder"></span>
        </div>
      </template>
      <div class="time">{{ time }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api';

export default {
  props: {
    msg: { required: true },
    showUser: Boolean,
    isOwn: Boolean,
  },
  computed: {
    time() {
      return (
        moment(this.msg.created_at).format('A h:mm') +
        // todo: debug show ack status
        (this.msg.ack ? ` (${this.msg.ack})` : '')
      );
    },
  },
  methods: {
    getCaption(msg) {
      if (!msg.metadata) return;
      const m = msg.metadata;
      return m.caption || m.document?.file_name;
    },
    resolveAttachment(url) {
      const u = new URL(url, api.defaults.baseURL);
      return u.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.message-row {
  margin-bottom: 3px;
  text-align: left;

  &.own {
    text-align: right;

    .content {
      background-color: #dcf8c6;
    }
  }
}

.username {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.content {
  position: relative;
  text-align: left;
  background: #fffaf5;
  padding: 8px 8px 12px 8px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  max-width: 50%;

  @media (max-width: 480px) {
    max-width: 60%;
  }

  .showUser.own &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -12px;
    top: 0;
    bottom: auto;
    border: 12px solid;
    border-color: #dcf8c6 transparent transparent transparent;
  }

  .showUser &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -12px;
    right: auto;
    top: 0;
    bottom: auto;
    border: 12px solid;
    border-color: white transparent transparent transparent;
  }
}

.timePlaceholder {
  display: inline-block;
  width: 110px;
}

.time {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 8px;
  bottom: 0;
  white-space: nowrap;
}
</style>
