<template>
  <v-dialog
    v-model="valueProxy"
    width="1000"
    max-width="100vw"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <CustomDialog ref="confirm" />
    <ValidationObserver ref="form" v-slot="{ valid }">
      <v-card>
        <v-card-title>
          {{ title }}
          <div class="mainTitle">Select Agent</div>
          <v-spacer />
          <v-btn icon @click="valueProxy = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <div style="display: flex; flex-direction: row; align-items: start">
              <ValidationProvider v-slot="{ errors }" name="agent" rules="required" slim>
                <v-autocomplete
                  outlined
                  dense
                  :items="items"
                  item-text="name"
                  :search-input.sync="autoSearch"
                  :item-value="x => x"
                  v-model="selectedAgent"
                  :error="!!errors.length"
                  :error-messages="errors"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.sid"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
              <v-card-actions style="padding-top: 0">
                <v-spacer />
                <v-btn color="primary" :disabled="!valid" @click="promptCreate()" v-if="isCreate">
                  Create
                </v-btn>
                <v-btn color="primary" :disabled="!valid" @click="promptUpdate()" v-if="!isCreate">
                  Add
                </v-btn>
              </v-card-actions>
            </div>
            <v-list>
              <v-list-item-title>
                <custom-label item-field-name="Assigned Agent" />
              </v-list-item-title>
              <v-list-item v-for="item in assigned" :key="item._id">
                <v-list-item-content v-text="item.name" />
                <v-btn @click="promptDelete(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-form>
        </v-card-text>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import ApiError from '@/api/ApiError';
import { crudRead } from '@/api/crud';
import { listProfiles } from '@/api/auth/membership';
import { assignChatToAgent, removeAgentFromChat } from '@/api/chat';
import CustomDialog from '@/components/CustomDialog';
import editDialogMixin from '@/services/editDialogMixin';
import api from '@/api';
import { mapActions } from 'vuex';
import CustomLabel from '../customFormLabel/customLabel';

export default {
  name: 'AgentAssignDialog',
  components: { CustomDialog, CustomLabel },
  mixins: [editDialogMixin],
  data() {
    return {
      crudURL: '',
      items: [],
      autoSearch: null,
      selectedAgent: null,
      assigned: {},
    };
  },
  props: {
    title: {},
    id: {},
  },
  watch: {
    async autoSearch(val) {
      const res = await listProfiles(api, {
        search: val,
        filter: { role: 'agent', assignedChats: { $nin: this.id } },
      });
      this.items = res.docs;
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      try {
        const temp = await crudRead(api, 'im/chats', this.id, {
          populate: ['_assignedAgents'],
        });
        this.assigned = temp._assignedAgents;
      } catch (err) {
        //empty
      }
    },
    async promptCreate() {},
    async promptUpdate() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        if (
          !(await this.$refs.confirm.open(
            'Reminder',
            `Confirm to add agent ${this.selectedAgent.name} into this chat?`,
          ))
        ) {
          return;
        }
        const model = await assignChatToAgent(api, this.id, this.selectedAgent._id);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `Agent ${this.selectedAgent.name} is assigned`,
          type: 'success',
          color: 'success',
        });
        await this.reload();
        this.selectedAgent = null;
        this.$refs.form.reset();
        return model;
      } catch (err) {
        this.handleError(err);
      }
    },
    async promptDelete(agent) {
      try {
        if (
          !(await this.$refs.confirm.open('Reminder', `Are you sure to delete ${agent.name}?`, {
            color: 'danger',
          }))
        ) {
          return;
        }
        await removeAgentFromChat(api, this.id, agent._id);
        await this.reload();
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `Agent ${agent.name} Removed`,
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
  },
};
</script>
